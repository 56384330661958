<template>
    <CInput v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive = true" @keypress="$func.keypressPrice" @keyup="handleKeyup" maxlength="15" horizontal>
    </CInput>
</template>

<script>    
   
    export default {
        name: 'CurrencyInput2',
        /*props: ["value", "label"],*/
        props: {
            value: {
                type: Number,
                default: 0
            },
            onKeyUp: { type: Function },
        },
        data: function () {
            return {
                horizontal: { label: 'col-3', input: 'col-9' },
                isInputActive: false
            }
        },
        computed: {
            displayValue: {
                get: function () {
                    if (this.isInputActive) {
                        // Cursor is inside the input field. unformat display value for user
                        if (this.value === undefined)
                            return '';
                        return this.value.toString();
                    } else {
                        // User is not modifying now. Format display value for user interface
                        //return "$ " + this.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                        //return this.value.toFixed(2).toLocaleString('vi-VN')
                        let _val = this.value;
                        if (_val === undefined)
                            _val = 0;

                        //console.log(' this.value=========> ', _val)
                        //return _val.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + " VND";
                        
                        return this.value.toLocaleString('en-US') + " VND";
                    }
                },
                set: function (modifiedValue) {
                                       
                    // Recalculate value after ignoring "$" and "," in user input
                    //let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""));
                    let newValue = parseFloat(modifiedValue.replace(/[^\d]/g, ""));
                    // Ensure that it is not NaN
                    if (isNaN(newValue)) {
                        newValue = 0
                    }
                    // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
                    // $emit the event so that parent component gets it
                    this.$emit('input', newValue);                                       
                }
            },            
        },
        methods: {
            handleKeyup() {
                if (this.onKeyUp instanceof Function)
                    this.onKeyUp();
            }
        }
    }
</script>


