<template>
    <AddEditForm :headerTitle="$t('pages.product.editProduct')" :backTitle="$t('pages.product.backToProductPage')" :onGoBack="onGoBack" :onSave="onUpdateProduct" :onSaveContinue="onUpdateProductAndContinue" :onDelete="onDelete">
        <template #headerLeft>
            <CButton type="button" color="secondary" @click="onCopy">{{ $t('common.copyProduct') }}</CButton>
        </template>
        <template #content>
            <Accordion :headerTitle="$t('pages.product.generalInfo')" v-if="editingProduct != null">
                <template v-slot:body>
                    <CForm>
                        <CInput :placeholder="$t('pages.product.enterProductName')"
                                v-model="editingProduct.name" required horizontal :is-valid="validator">
                            <template #label>
                                <div class="col-sm-3 col-form-label">
                                    {{ $t('common.name') }}
                                    <small class="requiredInput">(*)</small>
                                </div>
                            </template>
                        </CInput>

                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">
                                {{ $t('pages.product.category') }}
                                <small class="requiredInput">(*)</small>
                            </label>
                            <div class="col-sm-9 input-group">
                                <select v-model="editingProduct.categoryId" class="form-control">
                                    <option value="0">{{ $t('pages.product.selectCategory') }}</option>
                                    <option v-for="(item) in allCategories" v-bind:key="item.id" v-bind:value="item.id">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">
                                {{ $t('pages.product.supplier') }}
                                <small class="requiredInput">(*)</small>
                            </label>
                            <div class="col-sm-9 input-group">
                                <select v-model="editingProduct.providerId" class="form-control">
                                    <option value="0">{{ $t('pages.product.selectSupplier') }}</option>
                                    <option v-for="(item) in allSuppliers" v-bind:key="item.id" v-bind:value="item.id">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">
                                {{ $t('pages.product.retailUnit') }}
                                <small class="requiredInput">(*)</small>
                            </label>
                            <div class="col-sm-9 input-group">
                                <!--<select v-model="editingProduct.retailUnitId" class="form-control">
                                    <option value="0">{{ $t('pages.product.selectUnit') }}</option>
                                    <option v-for="(item) in unitList" v-bind:key="item.id" v-bind:value="item.id">
                                        {{item.name}}
                                    </option>
                                </select>-->


                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="vertical-align: middle; overflow: hidden;">
                                                <div>Tên đơn vị</div>
                                            </th>
                                            <th style="vertical-align: middle; overflow: hidden;">
                                                <div>Hệ số</div>
                                            </th>
                                            <th style="vertical-align: middle; overflow: hidden;">
                                                <div>Giá</div>
                                            </th>
                                            <th style="vertical-align: middle; overflow: hidden;"> <div></div></th>
                                        </tr>
                                    </thead>
                                    <tbody class="position-relative">
                                        <tr v-for="(item, index ) in productUnitList" :key="index">
                                            <td>
                                                <div>
                                                    <select v-model="item.unitId" class="form-control">
                                                        <option value="0">{{ $t('pages.product.selectUnit') }}</option>
                                                        <option v-for="(item) in unitList" v-bind:key="item.id" v-bind:value="item.id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <CInput v-model="item.conversionRate" horizontal></CInput>
                                            </td>
                                            <td>
                                                <CurrencyInput2 v-model="item.price">
                                                </CurrencyInput2>
                                            </td>
                                            <td>
                                                <a href="javascript:void(0)" @click="onRemoveUnit(item)">Xóa</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <CButton color="primary" variant="outline" square size="sm" @click="onAddUnit">Thêm</CButton>
                                            </td>
                                        </tr>
                                    </tbody><!---->
                                </table>
                            </div>
                        </div>


                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">
                                {{ $t('pages.product.wholesaleUnit') }}
                                <small class="requiredInput">(*)</small>
                            </label>
                            <div class="col-sm-9 input-group">

                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="vertical-align: middle; overflow: hidden;">
                                                <div>Tên đơn vị</div>
                                            </th>
                                            <th style="vertical-align: middle; overflow: hidden;">
                                                <div>Hệ số</div>
                                            </th>
                                            <th style="vertical-align: middle; overflow: hidden;">
                                                <div>Giá</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="position-relative">
                                        <tr>
                                            <td>
                                                <div>
                                                    <select v-model="editingProduct.wholesaleUnitId" class="form-control">
                                                        <option value="0">{{ $t('pages.product.selectUnit') }}</option>
                                                        <option v-for="(item) in unitList" v-bind:key="item.id" v-bind:value="item.id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <CInput v-model="editingProduct.wholesaleConversionRate" horizontal></CInput>
                                            </td>
                                            <td>
                                                <CurrencyInput2 v-model="editingProduct.wholesalePrice">
                                                </CurrencyInput2>
                                            </td>
                                        </tr>
                                    </tbody><!---->
                                </table>

                                <!-- <select v-model="editingProduct.wholesaleUnitId" class="form-control">
                                    <option value="0">{{ $t('pages.product.selectwholesaleUnit') }}</option>
                                    <option v-for="(item) in availUnits" v-bind:key="item.id" v-bind:value="item.id">
                                        {{item.name}}
                                    </option>
                                </select> -->
                            </div>
                        </div>

                        <CInput :placeholder="$t('pages.product.enterPackagingSpecification')" v-model="editingProduct.packagingSpecification" horizontal>
                            <template #label>
                                <div class="col-sm-3 col-form-label">
                                    {{ $t('pages.product.packagingSpecification') }}
                                    <small class="requiredInput">(*)</small>
                                </div>
                            </template>
                        </CInput>

                        <CInput :placeholder="$t('pages.product.enterSKU')"
                                v-model="editingProduct.sku" horizontal>
                            <template #label>
                                <div class="col-sm-3 col-form-label">
                                    {{ $t('pages.product.sku') }}
                                    <small class="requiredInput">(*)</small>
                                </div>
                            </template>
                        </CInput>

                        <CInput :label="$t('pages.product.madeIn')" :placeholder="$t('pages.product.enterMadeIn')"
                                v-model="editingProduct.madeIn" horizontal>
                        </CInput>

                        <CInput :label="$t('pages.product.certificate')" :placeholder="$t('pages.product.enterCertificate')"
                                v-model="editingProduct.certificate" horizontal>
                        </CInput>
                        <CInput :label="$t('pages.product.points')" :placeholder="$t('pages.product.enterPoints')"
                                v-model="editingProduct.points" horizontal>
                        </CInput>

                        <CInput :label="$t('pages.product.wholesalePoints')" :placeholder="$t('pages.product.enterWholesalePoints')"
                                v-model="editingProduct.wholesalePoints" horizontal>
                        </CInput>

                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">
                                {{ $t('common.description') }}
                            </label>
                            <div class="col-sm-9">
                                <!--<ckeditor v-model="editingProduct.description" :config="editorConfig"></ckeditor>-->
                                <ckeditor type="classic"
                                          v-model="editingProduct.description"
                                          @namespaceloaded="namespaceLoaded"
                                          :config="editorConfig"></ckeditor>
                            </div>
                        </div>

                        <div v-if="invalidInput" role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label"></label>
                            <div class="col-sm-9 input-group">
                                <div class="requiredInput">{{ errorInput }}</div>
                            </div>
                        </div>
                    </CForm>
                </template>
            </Accordion>
            <Accordion :headerTitle="$t('pages.product.picturesProduct')" v-if="editingProduct != null" expand>
                <template v-slot:body>
                    <ProductImageList :productId="editingProduct.id" :items="editingProduct.pictureModels" :onDelete="onDeletePicture" :onUploadImagesSuccess="onUploadImagesSuccess"></ProductImageList>
                </template>
            </Accordion>
            <Accordion :headerTitle="$t('pages.product.quantity')" v-if="editingProduct != null" expand>
                <template v-slot:body>
                    <ProductQuantityList :items="editingProduct.quantityModels"></ProductQuantityList>
                </template>
            </Accordion>

            <Accordion :headerTitle="$t('common.displayInfo')" v-if="editingProduct != null" expand>
                <template v-slot:body>
                    <CForm>
                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">{{ $t('common.published') }}</label>
                            <div class="col-sm-9 input-group">
                                <CSwitch :key="editingProduct.id"
                                         color="info"
                                         :checked.sync="editingProduct.published"
                                         size="sm" />
                            </div>
                        </div>

                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">{{ $t('common.showOnHomepage') }}</label>
                            <div class="col-sm-9 input-group">
                                <CSwitch :key="editingProduct.id"
                                         color="info"
                                         :checked.sync="editingProduct.showOnHomepage"
                                         size="sm" />
                            </div>
                        </div>

                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">{{ $t('common.markedAsHot') }}</label>
                            <div class="col-sm-9 input-group">
                                <CSwitch :key="editingProduct.id"
                                         color="info"
                                         :checked.sync="editingProduct.markedAsHot"
                                         size="sm" />
                            </div>
                        </div>

                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">{{ $t('common.markedAsNew') }}</label>
                            <div class="col-sm-9 input-group">
                                <CSwitch :key="editingProduct.id"
                                         color="info"
                                         :checked.sync="editingProduct.markedAsNew"
                                         size="sm" />
                            </div>
                        </div>

                    </CForm>
                </template>
            </Accordion>

            <div v-if="!isLoadingProductDetail && editingProduct == null">
                {{ $t('pages.product.noProduct') }}
            </div>
            <CSpinner color="primary" variant="grow" v-if="isLoadingProductDetail"></CSpinner>
            <Confirmation ref="confirmation"></Confirmation>
        </template>


    </AddEditForm>

</template>

<script>

    import { mapGetters, mapState } from 'vuex'
    //    import UploadImages from "vue-upload-drop-images"
    import Confirmation from '@/components/Confirmation.vue'
    import i18n from '@/plugins/i18n'

    import { globalConfigs } from '@/global/index'
    console.log("Global", globalConfigs);
    import Accordion from '@/components/Accordion.vue'
    import ProductQuantityList from '@/components/products/ProductQuantityList.vue'
    import ProductImageList from '@/components/products/ProductImageList.vue'
    import CurrencyInput2 from '@/components/CurrencyInput2.vue'
    import AddEditForm from '@/components/AddEditForm.vue'

    import _ from "lodash";


    export default {
        name: 'productadd',
        data() {
            return {
                productUnitList: null,
                collapseProductInfo: true,
                horizontal: { label: 'col-3', input: 'col-9' },
                files: null,
                itemsPerPage: 10,
                warningModal: false,
                warningModalMessage: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod",
                fields: [                      // Array of column object data
                    //{ key: 'id', label: 'Picture' },
                    { key: "product_image", label: i18n.t('pages.product.picture') },
                    {
                        key: 'user_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ],
                unitFields: [
                    { key: "name", label: 'Tên đơn vị' },
                    { key: "price", label: 'Giá' },
                    { key: "unit_actions", label: '' }
                ],
                invalidInput: false,
                errorInput: '',
                editorConfig: globalConfigs.CKEditorConfig
            }
        },
        components: {
            //UploadImages,
            Confirmation,
            Accordion,
            ProductQuantityList,
            ProductImageList,
            CurrencyInput2,
            AddEditForm
        },
        computed: {
            ...mapState('product', ['allProducts', 'editingProduct', 'hasError', 'errorMessage', 'isLoadingProductDetail', 'unitList']),
            ...mapGetters('supplier', ['allSuppliers']),
            ...mapGetters('category', ['allCategories']),
            availUnits() {
                var retVal = [];
                if (this.productUnitList != null && this.unitList != null) {
                    for (var i = 0; i < this.productUnitList.length; i++) {
                        if (this.productUnitList[i].unitId > 0) {
                            var currentUnitId = this.productUnitList[i].unitId
                            var existingUnit = _.find(this.unitList, function (o) { return o.id == currentUnitId; })
                            console.log('existing unit', existingUnit)
                            if (existingUnit != null)
                                retVal.push(existingUnit)
                        }
                    }
                }
                
                return retVal;
            }
        },

        watch: {
            editingProduct(newVal) {
                if (this.productUnitList == null)
                    this.productUnitList = newVal.productUnits != null ? newVal.productUnits : [];
            }
        },

        methods: {
            validator(val) {
                return val ? val.length > 0 : false
            },
            onlyNumbers(event) {
                let keyCode = event.keyCode ? event.keyCode : event.which;
                if (keyCode < 48 || keyCode > 57) {
                    // 46 is dot
                    event.preventDefault();
                }
            },
            onGoBack() {
                this.$router.push('/products/list');
            },

            async onDelete() {
                if (this.editingProduct && this.editingProduct.id > 0) {

                    this.$refs.confirmation.show(i18n.t('pages.product.confirmDeleteProduct'), async () => {
                        console.log('Delete product', this.editingProduct);
                        var retVal = await this.$store.dispatch("product/deleteProduct", this.editingProduct.id);
                        if (retVal == true) {
                            this.onGoBack();
                        }
                    });
                }

            },

            async UpdateProduct() {
                if (!this.validator(this.editingProduct.name)) {
                    //this.invalidInput = true;
                    //this.errorInput = this.$t('pages.product.enterProductName')
                    this.$store.dispatch('alert/error', this.$t('pages.product.productNameRequired'), { root: true });
                    return;
                }

                if (this.editingProduct.categoryId == 0
                    || this.editingProduct.providerId == 0
                    || this.editingProduct.sku == '') {
                    //this.invalidInput = true;
                    //this.errorInput = this.$t('pages.product.requiredField')
                    this.$store.dispatch('alert/error', this.$t('pages.product.requiredField'), { root: true });
                    return;
                }

                if (this.productUnitList.length == 0) {
                    this.$store.dispatch('alert/error', 'Thêm đơn vị bán lẻ', { root: true });
                    return;
                }
                var validUnits = _.filter(this.productUnitList, (item) => item.unitId > 0);
                if (validUnits == null || validUnits.length == 0) {
                    this.$store.dispatch('alert/error', 'Thêm đơn vị bán lẻ', { root: true });
                    return;
                }
                for (var i = 0; i < validUnits.length; i++) {
                    if (validUnits[i].unitId > 0) {
                        var unitPrice = parseFloat(validUnits[i].price);
                        var rate = parseFloat(validUnits[i].conversionRate);
                        if (isNaN(unitPrice) || unitPrice <= 0 || isNaN(rate) || rate <= 0) {
                            this.$store.dispatch('alert/error', 'Kiểm tra lại giá, hệ số của từng đơn vị bán lẻ. Chưa hợp lý', { root: true });
                            return;
                        }
                    }
                }

                this.editingProduct.productUnits = this.productUnitList;

                //Check wholesale unitId
                if(this.editingProduct.wholesaleUnitId <= 0){
                    this.$store.dispatch('alert/error', 'Đơn vị bán sỉ không hợp lệ', { root: true });
                    return;
                }

                if (isNaN(this.editingProduct.wholesalePrice) || this.editingProduct.wholesalePrice <= 0 || isNaN(this.editingProduct.wholesaleConversionRate) || this.editingProduct.wholesaleConversionRate <= 0){
                    this.$store.dispatch('alert/error', 'Kiểm tra lại giá, hệ số của từng đơn vị bán sỉ. Chưa hợp lý', { root: true });
                    return;
                }

                var retVal = await this.$store.dispatch("product/saveProduct");
                return retVal.success;
            },
            async onUpdateProduct() {
                var resq = await this.UpdateProduct();
                if (resq)
                    this.$router.push('/products/list');
            },

            async onUpdateProductAndContinue() {
                var resq = await this.UpdateProduct();
            },
            async onUploadImagesSuccess() {
                this.$store.dispatch('toast/displayNotification', { text: i18n.t('noticesUploadimage') }, { root: true });
            },
            onCancel() {
                console.log("Cancel updating Product.......");
                this.$router.push('/products/list');
            },
            async handleImages(files) {
                this.files = files;
            },
            async onDeletePicture(item) {
                this.$refs.confirmation.show(i18n.t("pages.product.confirmDeletePicture"), async () => {
                    console.log('start deleting item', item)
                    var retVal = await this.$store.dispatch("product/deleteProductImage", item.id);
                });
            },
            onCopy(item) {
                if (this.editingProduct == null)
                    return;

                this.$refs.confirmation.show(i18n.t("pages.product.confirmCopyProduct") + ": " + this.editingProduct.name, async () => {
                    console.log('start deleting item', item)
                    var retVal = await this.$store.dispatch("product/copy", this.editingProduct.id);
                });
            },
            namespaceLoaded: function (namespace) {
                console.log('CKEDITOR version: ', namespace.version);
            },

            async onRemoveUnit(item) {
                var existingIdx = -1;
                for (var i = 0; i < this.productUnitList.length; i++) {
                    if (this.productUnitList[i] == item) {
                        existingIdx = i;
                        console.log('found deleted item at ' + i.toString());
                    }
                }
                if (existingIdx >= 0) {
                    this.productUnitList.splice(existingIdx, 1);
                }
            },
            async onAddUnit() {
                var emptyUnitItem = _.find(this.productUnitList, function (o) { return o.unitId == 0; });
                if (emptyUnitItem == null) {
                    this.productUnitList.push({ id: 0, unitId: 0, price: 0 });
                }
            }
        },
        mounted() {
            this.$store.dispatch("product/editProduct", this.$route.params.id);
            this.$store.dispatch("supplier/getAllSuppliers");
            this.$store.dispatch("category/getAllCategories");
            this.$store.dispatch("product/getAllUnits");
        },
    }
</script>

<style scoped>
    .rotating {
        transition: transform 1s ease-in-out;
    }

        .rotating:hover {
            transform: rotateZ(360deg);
        }
</style>