import { render, staticRenderFns } from "./ProductEdit.vue?vue&type=template&id=9340634c&scoped=true&"
import script from "./ProductEdit.vue?vue&type=script&lang=js&"
export * from "./ProductEdit.vue?vue&type=script&lang=js&"
import style0 from "./ProductEdit.vue?vue&type=style&index=0&id=9340634c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9340634c",
  null
  
)

export default component.exports