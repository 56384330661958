<template>
    <CDataTable :items="items"
                :fields="fields">
        <template #agent="{item}">
            <td class="py-2">
                <span v-if="item.agent != null">{{item.agent.name}}</span>
            </td>
        </template>

        <template #frozenQuantity="{item}">
            <td class="py-2">
                <span :class="{'text-danger font-weight-bold': item.frozenQuantity > 0 }">
                    {{item.frozenQuantity}}
                </span>
            </td>
        </template>

        <template #availableQuantity="{item}">
            <td class="py-2">
                <span :class="[ (item.quantity - item.frozenQuantity == 0) ? 'text-danger font-weight-bold': 'text-primary' ]">
                    {{item.quantity - item.frozenQuantity}}
                </span>
            </td>
        </template>

        <template #createdDate="{item}">
            <td class="py-2">               
                <label v-text="formatDate(item.createdDate)" />
            </td>
        </template>

        <template #updatedDate="{item}">
            <td class="py-2">
                <span v-if="item.updatedDate != null">
                    {{ $moment(item.updatedDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') }}
                </span>
            </td>
        </template>
    </CDataTable>
</template>

<script>

    import i18n from '@/plugins/i18n'
    export default {
        name: 'ProductQuantityList',
        props: {
            items: {
                default: null,
                type: Array
            }
        },
        data() {
            return {
                fields: [   
                    { key: "agent", label: i18n.t('pages.product.agent') },
                    { key: "quantity", label: i18n.t('pages.product.quantity') },
                    { key: "frozenQuantity", label: i18n.t('pages.product.frozenQuantity') },
                    { key: "availableQuantity", label: i18n.t('pages.product.availableQuantity') },
                    { key: "status", label: i18n.t('pages.product.status') },
                    { key: "createdDate", label: i18n.t('pages.product.createdDate') },
                    { key: "updatedDate", label: i18n.t('pages.product.updatedDate') }
                ]
            };
        },
        methods: {
            formatDate(date) {
                console.log("saveProductInfođ=====: ", date);
                if (date === null || typeof (date) === 'undefined')
                    return '';
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
            },
        },
    }
</script>

<style scoped>
    header {
        /*background: #3c4b64*/
    }
    h5 {
        font-size: 1.09375rem;
    }
        h5 a {
            /* color: #fff;*/
            color: #444;
        }

    .mb-0 {
        margin-bottom: 0 !important;
    }
</style>
