<template>
    <div>
        <CDataTable :items="items"
                    :fields="fields">

            <template #product_image="{item}">
                <td>
                    <img :src="item.thumbImageUrl" />
                </td>
            </template>

            <template #displayOrder="{item}">
                <td class="py-2">
                    <span>{{item.displayOrder ? item.displayOrder.toString() : 1}}</span>
                </td>
            </template>

            <template #user_detail_actions="{item}">
                <td class="py-2">
                    <CButton color="primary"
                             variant="outline"
                             square
                             size="sm"
                             @click="onDeletePicture(item)">
                        {{ $t('common.delete') }}
                    </CButton>
                </td>
            </template>
        </CDataTable>

        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <strong>{{ $t('pages.product.addPicture') }}</strong>
                    </CCardHeader>
                    <CCardBody>
                        <div>
                            <UploadImages ref="uploadimages" :max="10" @change="handleImages"
                                          :uploadMsg="$t('common.uploadImageMessage')" />
                        </div>
                        <div class="form-actions">
                            <CButton type="button" color="primary" @click="onUploadImages">{{ $t('pages.product.uploadProductImages') }}</CButton>
                        </div>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import UploadImages from "vue-upload-drop-images"
    import i18n from '@/plugins/i18n'
    export default {
        name: 'ProductImageList',
        props: {
            items: {
                default: null,
                type: Array
            },
            productId: {
                default: null,
                type: Number
            },
            onDelete: { type: Function },
            onUploadImagesSuccess: { type: Function },
            onUploadImagesFail: { type: Function },
        },
        data() {
            return {
                files: null,
                fields: [                     
                    { key: "product_image", label: i18n.t('pages.product.picture') },
                    { key: "displayOrder", label: i18n.t('pages.product.displayOrder') },
                    { key: "title", label: i18n.t('pages.product.title') },
                    { key: "alternateText", label: i18n.t('pages.product.alternateText') },
                    {
                        key: 'user_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ],
            };
        },
        components: {
            UploadImages,
        },
        methods: {
            async onDeletePicture(item) {
                if (this.onDelete instanceof Function)
                    this.onDelete(item);
            },

            async handleImages(files) {
                this.files = files;
            },
            async onUploadImages() {
                if (this.productId == null) {
                    this.$store.dispatch('alert/error', this.$t('pages.product.validation.selectProductFirst'), { root: true });
                    return;
                }

                var retVal = await this.$store.dispatch("product/saveProductImages", { productId: this.productId, files: this.files });

                if (retVal == true) {
                    this.$store.dispatch("product/getAllImageByProductId", this.productId);
                    if (this.onUploadImagesSuccess instanceof Function)
                        this.onUploadImagesSuccess();
                }
                else {
                    if (this.onUploadImagesFail instanceof Function)
                        this.onUploadImagesFail();
                }
                
            },
        },
    }
</script>

<style scoped>
    header {
        /*background: #3c4b64*/
    }
    h5 {
        font-size: 1.09375rem;
    }
        h5 a {
            /* color: #fff;*/
            color: #444;
        }

    .mb-0 {
        margin-bottom: 0 !important;
    }
</style>
