var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"agent",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(item.agent != null)?_c('span',[_vm._v(_vm._s(item.agent.name))]):_vm._e()])]}},{key:"frozenQuantity",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('span',{class:{'text-danger font-weight-bold': item.frozenQuantity > 0 }},[_vm._v(" "+_vm._s(item.frozenQuantity)+" ")])])]}},{key:"availableQuantity",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('span',{class:[ (item.quantity - item.frozenQuantity == 0) ? 'text-danger font-weight-bold': 'text-primary' ]},[_vm._v(" "+_vm._s(item.quantity - item.frozenQuantity)+" ")])])]}},{key:"createdDate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('label',{domProps:{"textContent":_vm._s(_vm.formatDate(item.createdDate))}})])]}},{key:"updatedDate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(item.updatedDate != null)?_c('span',[_vm._v(" "+_vm._s(_vm.$moment(item.updatedDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss'))+" ")]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }